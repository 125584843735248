@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

@media(max-width:840px) {
  .calcmodal-body {
    width: 100% !important;
  }

  .chart-notes-wrapper {
    width: 100% !important;
  }
}

.calcmodal-body {
  font-family: 'Comfortaa', cursive !important;
  background-color: var(--theme-text);
  color: var(--theme-contrast);
  overflow: auto;
  padding: 0px !important;
  margin: auto;
  width: 80%;
  font-size: 16px !important;
  border: solid 2px var(--theme-contrast);
  border-radius: 5px;
  font-weight: bold !important;
  padding: 20px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.rs-modal-title {
  white-space: pre-line !important;
}

@media(max-width:650px) {
  .rs-modal-wrapper {
    display: flex;
    align-items: center;
  }

  .buttons-profile {
    display: flex;
    flex-direction: column;
  }

  .buttons-profile>* {
    padding: 5px;
    margin: 5px;
  }

  .inc {
    font-size: larger;
  }

  #btn-confirm {
    width: auto !important;
    padding: 5px 0 !important;
  }
}

.welcome-modals {
  max-width: 600px;
  width: auto;
}

h1,
h2,
h3 {
  user-select: none;
}

.note-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--theme-main);
  margin: 10px 0 0 0;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--theme-text);
  border: 3px solid var(--theme-contrast);
  /* width: fit-content; */
}

.note-div:hover {
  background-color: var(--theme-text);
  color: var(--theme-main);
}

.note-div-child {
  background-color: var(--theme-main);
  margin: 0 0 10px 0;
  padding: 5px;
  border-radius: 5px;
  color: var(--theme-text);
  border: 3px solid var(--theme-contrast);
  border-top: none;
}

.nav-element:hover {
  color: var(--theme-text);
  background-color: var(--theme-main);
}

#colorscale {
  margin: 9px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#colorScale>div {
  border: 2px solid var(--theme-contrast);
  border-radius: 5px;
  padding: 3px;
}

html {
  min-height: 100vh;
}

body,
#root {
  margin: auto 0;
  min-height: 100vh;
}

.calcmodal-body select,
.calcmodal-body input {
  color: black;
  /* width: 95%; */
  margin: auto;
  padding: 5px;
  background: white;
}

.d-block {
  margin: auto;
  display: block;
}

.calc-title {
  margin-bottom: 15px;
}

.data {
  color: var(--theme-contrast);
  background-color: var(--theme-text);
}

.colorSq {
  min-height: 20px;
  border: var(--theme-contrast) 3px;
  min-width: 20px;
  border: 2px solid var(--theme-contrast);
  border-radius: 5px;
  padding: 3px;
}

.rec-result {
  padding: 5px;
  display: block;
  border: 2px solid var(--theme-contrast);
  text-shadow: 1px 1px 1px #000;
}

input,
button {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}


.data,
input {
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  outline: none;
  margin-bottom: 20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.rs-picker-select {
  margin-bottom: 20px;
}

.data input:focus {
  border: 1px solid #777;
}

.data button {
  /* color: var(--theme-contrast); */
  border: 2px solid var(--theme-contrast);
  background-color: var(--theme-main);
  border-radius: 5px;
  padding: 15px;
  display: block;
  width: 100%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.user-panel {
  color: black;
  border: 2px solid var(--theme-contrast);
  margin: 10px 0;
  padding: 5px;
  border-radius: 5px;
}

.info-div {
  background-color: var(--theme-secondary);
  padding: 10px;
  border-radius: 10px;
  margin: 9px 0;
  border: 3px solid var(--theme-contrast);
  color: var(--theme-main)
}

.ul-dot>li {
  list-style: circle;
}

.manual img {
  max-width: 100%;
}

.chart-notes-wrapper {
  display: flex;
  width: 80%;
  margin: auto;
}

.inject-button {
}
.inject-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 5px;
  bottom: 20%
}
.inject-div  button {
  background-color: white;
  color: black;
  margin: 5px;
  width: 100%;
}